<template lang='pug'>
  .sign-in-banner
    .heading {{ leftMsg[0] }}
    .message(@mouseover='pauseScroll = true' @mouseleave='pauseScroll = false')
      .text-scroll
        ul.list(:class="pauseScroll ? 'pause-scroll' : 'start-scroll'")
          li.item(v-for='item in midMsg', :key='item') {{ item }}
          li.item
            a.beauty-pass(:href='beautyPassPath()') {{ moreMsg[0] }}
    .buttons
      a.btn.sign-in(:href='signInPath()' v-text='$t("signInBanner.signIn", localeCountry)')
      a.btn.register(:href='registerPath()' v-text='$t("signInBanner.register", localeCountry)')
</template>

<script>
import get from 'lodash/get'
import { sessionUtil, beautyPassUtil } from '@/mixins/pathUtil'

export default {
  name: 'SignInBanner',

  mixins: [
    sessionUtil,
    beautyPassUtil
  ],

  data () {
    return {
      pauseScroll: false
    }
  },

  computed: {
    localeCountry: () => I18n.language === 'lng_en' ? I18n.siteCountryLs : '',
    leftMsg: function () {
      const left = get(vueSupport, 'signInBanner.left')
      return this.processMsg(left)
    },
    midMsg: function () {
      const mid = get(vueSupport, 'signInBanner.mid')
      return this.processMsg(mid)
    },
    moreMsg: function () {
      const more = get(vueSupport, 'signInBanner.more')
      return this.processMsg(more)
    }
  },
  methods: {
    processMsg: function (msg) {
      if (!msg) {
        return []
      }

      if (!msg[I18n.language]) {
        return []
      }

      return msg[I18n.language].split('\r\n')
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-in-banner {
  // Temporary hardcoded hexcode for BBDY.
  background: linear-gradient(270deg, #ffbda9 0%, #ff7e67 100%);
  color: $white;
  height: 45px;
  font-size: 16px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;

  .heading {
    width: 25%;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttons {
    width: 35%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn {
    width: 148px;
    margin: auto 6px;
    padding: 9px 10px 5px;
    font-size: 14px;
    line-height: 14px;
  }

  .register{
    background-color: $white;
    color: $black;
    border: 1px solid $black;
  }

  .sign-in {
    background-color: $black;
    color: $white;
    border: 1px solid $black;
  }

  .message {
    height: 100%;
    width: 30%;

    &:hover {
      height: 100%;
    }
  }

  .text-scroll {
    margin: auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    height: 45px;
  }

  .list {
    font-weight: normal;
    overflow: hidden;
    margin: 4px 0 0;
    -moz-transform: translateY(8%);
    -webkit-transform: translateY(8%);
    transform: translateY(8%);
    -moz-animation: scrollUp 20s linear infinite;
    -webkit-animation: scrollUp 20s linear infinite;
    animation: scrollUp 20s linear infinite;

    li {
      padding: 12px 10px;
      list-style: none;
    }
  }

  .pause-scroll {
    -moz-animation-play-state: paused;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  .start-scroll {
    -moz-animation-play-state: running;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }

  a.beauty-pass {
    text-decoration: underline;
    color: white;
  }
}

@keyframes scrollUp {
  0% {
    -moz-transform: translateY(8%);
    -webkit-transform: translateY(8%);
    transform: translateY(8%);
  }
  100% {
    -moz-transform: translateY(-92%);
    -webkit-transform: translateY(-92%);
    transform: translateY(-92%);
  }
}

@include media-breakpoint-up(sm) {
  .sign-in-banner {
    .message {
      width: 68%;
      margin: auto 2px;
    }
  }
}

@include media-breakpoint-up(md) {
  .sign-in-banner {
    .message {
      width: 34%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .sign-in-banner {
    .heading {
      width: 25%;
      margin-left: 12px;
    }

    .message {
      width: 30%;
    }

    .buttons {
      width: 35%;
    }
  }
}
</style>
