import Vue from 'vue'
import router from '../../../router'
import store from '../../../store'
import i18n from '@/modules/i18n'

import SignInBanner from './sign-in-banner.vue'

const vue = new Vue({
  el: '#sign-in-banner',
  router,
  store,
  i18n,
  components: {
    SignInBanner
  }
})

window.vue = vue
