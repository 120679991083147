import { mapGetters } from 'vuex'

const brandUtil = {
  computed: {
    ...mapGetters([
      'findBrandById'
    ])
  },
  methods: {
    brandPath (brand) {
      let brandSlug
      if (brand.type === 'search-queries' || brand.attributes) {
        brandSlug = brand.attributes['slug-url']
      } else {
        brandSlug = this.findBrandById(brand.id).attributes['slug-url']
      }

      const addSearchQuery = this.searchQuery && this.searchQuery.length > 0

      const path = {
        name: 'brands-show',
        params: {
          id: brandSlug
        },
        ...(addSearchQuery > 0 ? {
          query: {
            q: this.searchQuery
          }
        } : {})
      }

      return path
    }
  }
}

const categoryUtil = {
  computed: {
    ...mapGetters([
      'findCategoryById'
    ])
  },
  methods: {
    categoryPath (category) {
      let categorySlug

      if (category.attributes) {
        categorySlug = category.attributes['slug-url']
      } else {
        categorySlug = this.findCategoryById(category.id).attributes['slug-url']
      }

      const path = {
        name: 'categories-show',
        params: {
          id: categorySlug.split('/')
        }
      }

      if (this.searchQuery) {
        path.query = { q: this.searchQuery }
      }

      return path
    }
  }
}

const saleUtil = {
  methods: {
    salePath (category = null) {
      return {
        name: 'landing-pages-sale',
        params: {
          ...(category ? { category: category.attributes['slug-url'] } : undefined)
        }
      }
    }
  }
}

const sessionUtil = {
  computed: {
    ...mapGetters(['isMobileApp'])
  },
  methods: {
    registerPath () {
      return (this.isMobileApp ? 'sephora://register' : '/users/sign_up')
    },
    signInPath () {
      return (this.isMobileApp ? 'sephora://sign_in' : '/users/sign_in')
    }
  }
}

const beautyPassUtil = {
  methods: {
    beautyPassPath () {
      return (this.isMobileApp ? 'sephora://beautypass' : '/beauty-pass')
    }
  }
}

export { brandUtil, categoryUtil, saleUtil, sessionUtil, beautyPassUtil }
